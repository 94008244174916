
import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';


const Saas2 = React.lazy(() => import('../pages/landings/Saas/Saas2'))
const AdsPage = React.lazy(() => import('../pages/landings/Saas/AdsPage'))
const Privacy=React.lazy(()=>import("../pages/landings/Saas/Policy/Privacy"))
const Refund=React.lazy(()=>import("../pages/landings/Saas/Policy/Refund"))
const Terms=React.lazy(()=>import("../pages/landings/Saas/Policy/Terms"))
const Whatsapp = React.lazy(() => import('../pages/landings/Saas/Whatsapp'))
const Billing = React.lazy(() => import('../pages/landings/Saas/Billing'))
const Management = React.lazy(() => import('../pages/landings/Saas/Management'))
const Ordering = React.lazy(() => import('../pages/landings/Saas/Ordering'))
const InventoryManagement = React.lazy(() => import('../pages/landings/Saas/InventoryManagement'))
const ExpenseManagement = React.lazy(() => import('../pages/landings/Saas/ExpenseManagement'))
const StaffManagement = React.lazy(() => import('../pages/landings/Saas/StaffManagement'))
const StaffLoadManagement = React.lazy(() => import('../pages/landings/Saas/StaffLoadManagement'))
const PosManagement = React.lazy(() => import('../pages/landings/Saas/PosManagement'))
const MenuManagement = React.lazy(() => import('../pages/landings/Saas/MenuManagement'))
const OrderRecords = React.lazy(() => import('../pages/landings/Saas/OrderRecords'))






const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            // root route
            path: '/',
            element: <LoadComponent component={Saas2} />,
        },
        {
            // root route
            path: '/ads',
            element: <LoadComponent component={AdsPage} />,
        },
        {
            path:"/privacy",
            element:<LoadComponent component={Privacy}/>
        },
        {
            path:"/refund",
            element:<LoadComponent component={Refund}/>
        },
        {
            path:"/terms",
            element:<LoadComponent component={Terms}/>
        },
        {
            path:"/sales",
            element:<LoadComponent component={Whatsapp}/>

        },
        {
            path:"/billing",
            element:<LoadComponent component={Billing}/>

        },
        {
            path:"/management",
            element:<LoadComponent component={Management}/>

        },
        {
            path:"/ordering",
            element:<LoadComponent component={Ordering}/>

        },
        {
            path:"/inventory",
            element:<LoadComponent component={InventoryManagement}/>

        },
        {
            path:"/expense",
            element:<LoadComponent component={ExpenseManagement}/>

        },
        {
            path:"/staff",
            element:<LoadComponent component={StaffManagement}/>

        },
        {
            path:"/staffload",
            element:<LoadComponent component={StaffLoadManagement}/>

        },
        {
            path:"/pos",
            element:<LoadComponent component={PosManagement}/>

        },
        {
            path:"/menu",
            element:<LoadComponent component={MenuManagement}/>

        },
        {
            path:"/orders",
            element:<LoadComponent component={OrderRecords}/>

        },
        {
            // public routes
            path: '/',
            children: [
              
               
                {
                    path: 'home',
                    element: <LoadComponent component={Saas2} />,
                },

            ],
        },
    
    ]);
};

export default AllRoutes;
